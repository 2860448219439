import * as Sentry from '@sentry/browser'
import { UserData, LoggerService, Context } from './Logger'

export class LoggerServiceSentry implements LoggerService {
  constructor(init: { dsn: string; environment?: string; tracesSampleRate?: number }) {
    Sentry.init({
      environment: 'development',
      /* eslint-disable-next-line deprecation/deprecation */
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: 0.1,
      ...init,
    })
  }

  setUserData(u: UserData): void {
    Sentry.setUser({
      id: u.id,
    })
  }

  logError(e: Error | undefined, context?: Context): void {
    Sentry.captureException(e, context)
  }
}
